import React from 'react';

function DeleteAccountLink() {
  const handleOpenLink = () => {
    window.open('https://itm.co.nz/itm-delete-app-account', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="account__remove_account">
      <a  onClick={handleOpenLink}>DELETE ACCOUNT REQUEST</a>
    </div>
  );
}

export default DeleteAccountLink;