import React, { useEffect } from 'react';
import Button from '../Form/Button';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadStoresByLocation } from '../../actions/StoresActions';

const ChooseITMStore = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  
  const geoLocatedStore = useSelector((state) =>
    state.stores.storesByLocation.length > 0
      ? state.stores.storesByLocation[0]
      : null,
  );

  useEffect(() => {
    if (geoLocatedStore) {
      history.push(`/sign-up/closest-store?id=${geoLocatedStore.NodeID}`);
    }
  }, [geoLocatedStore]);

  const handleGeoLocation = () => {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        dispatch(loadStoresByLocation(latitude, longitude));
      },
      () => {
        history.push('/sign-up/search-stores');
      },
    );
  };

  return (
    <div className="choose-itm-store sticky-bottom text--centered">
      <div className="choose-itm-store__wrapper">
        <Button
          text="Next"
          type="primary"
          handleSubmit={handleGeoLocation}
        />
        <Link
          to="/sign-up/search-stores"
          className="choose-itm-store__link sticky-bottom__link transform-none"
        >
          <p className="text--bold">
            <span>or</span>search for your local ITM
          </p>
        </Link>
      </div>
    </div>
  );
};

ChooseITMStore.propTypes = {

};

ChooseITMStore.defaultProps = {

};

export default ChooseITMStore;
