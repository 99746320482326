import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/AccountsActions';
import { ToastNotification } from '../components/ToastNotification';
import HeadPage from '../components/HeadPage';
import { formatDateForDatePicker } from '../util/dates';
import { pageViewGA } from '../services/GoogleAnalytics';
import AccountDetails from '../components/Accounts/AccountDetails';
import LocateStore from '../components/Accounts/LocateStore';
import LocalITM from '../components/Accounts/LocalItm';
import LBPDetails from '../components/Accounts/LBPDetails';
import PriorityCardLink from '../components/Accounts/PriorityCardLink';
import PriorityCardSettings from '../components/Accounts/PriorityCardSettings';
import TradeClubSettings from '../components/Accounts/TradeClubSettings';
import TradeClubLink from '../components/Accounts/TradeClubLink';
import StickyNavigation from '../components/StickyNavigation';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import DeleteAccount from '../components/Accounts/DeleteAccount';
class AccountsContainer extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { actions, pageType } = this.props;

    switch (pageType) {
      case 'Account Details':
        pageViewGA('Account Details');
        break;
      case 'LBP Details':
        pageViewGA('LBP Details');
        break;
      case 'NOTIFICATION SETTINGS':
        pageViewGA('NOTIFICATION SETTINGS');
        break;
      case 'Your local ITM':
        pageViewGA('Locate Your local ITM');
        break;
      case 'Local ITM':
        pageViewGA('Your Local ITM');
        break;
      case 'Priority Card Settings':
        pageViewGA('Priority Card Settings');
        break;
      case 'Priority Card Link':
        pageViewGA('Priority Card Link');
        break;
      case 'Trade club settings':
        pageViewGA('Trade club settings');
        break;
      case 'Trade club link':
        pageViewGA('Trade club link');
        break;
      default:
        pageViewGA('Your Account');
        break;
    }
    actions.loadUserDetails();
  }

  renderAccount() {
    const { profile } = this.props;
    const customerType = profile?.UserSettings?.CustomerType;

    const checkCustomerType = () => {
      if (customerType == 4 ) {
        return (
          <Link to="/account/trade-club-settings">
            <div className="account__trade">
              <div className="account__trade__wrapper">
                <div className="account__trade--icon">
                  <i className="fas fa-address-card"></i>
                </div>
                <div className="account__priority--text">
                  <h4>Trade Club Settings</h4>
                </div>
              </div>
              <div className="account__priority--right-arrow">
                <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </Link>
        );
      } else if (customerType == 1 || customerType == 2 || customerType == 3) {
        return (
          <Link to="/account/priority-card-settings">
            <div className="account__priority">
              <div className="account__priority__wrapper">
                <div className="account__priority--icon">
                  <i className="fas fa-address-card"></i>
                </div>
                <div className="account__priority--text">
                  <h4>Priority Card settings</h4>
                </div>
              </div>
              <div className="account__priority--right-arrow">
                <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </Link>
        );
      }
    };

    const checkLBPNumber = () => {
      const { profile } = this.props;

      const LBPnumber = profile?.LBP?.LBPNumber || '';

      if (LBPnumber > 0) {
        return (
          <Link to="/account/lbp-details">
            <div className="account__lbp-details">
              <div className="account__lbp-details__wrapper">
                <div className="account__lbp-details--icon">
                  <i className="fas fa-briefcase"></i>
                </div>
                <div className="account__lbp-details--text">
                  <h4>LBP Details</h4>
                </div>
              </div>
              <div className="account__lbp-details--right-arrow">
                <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </Link>
        );
      }
    };

    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__heading">
            <HeadPage pageTitle="Your Account" hasBackButton={false} />
          </div>
          <Link to="/account/details">
            <div className="account__details">
              <div className="account__details__wrapper">
                <div className="account__details--icon">
                  <i className="fas fa-user"></i>
                </div>
                <div className="account__details--text">
                  <h4>Account Details</h4>
                </div>
              </div>
              <div className="account__details--right-arrow">
                <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </Link>
          {checkLBPNumber()}

          {checkCustomerType()}

          <Link
            id={profile?.Store?.NodeID}
            to={`/account/local-itm/?id=${profile?.Store?.NodeID}`}
          >
            <div className="account__local-itm">
              <div className="account__local-itm__wrapper">
                <div className="account__local-itm--icon">
                  <i className="fas fa-map-marked-alt"></i>
                </div>
                <div className="account__local-itm--text">
                  <h4>Your Local ITM</h4>
                  <p>{profile?.Store?.Name}</p>
                </div>
              </div>
              <div className="account__local-itm--right-arrow">
                <i className="fas fa-arrow-right"></i>
              </div>
            </div>
          </Link>
          
          <DeleteAccount></DeleteAccount>
          <div className="account__logout">
            <Link to="/logout">
              <span>
                <i className="far fa-sign-out"></i>
              </span>
              &nbsp;Logout
            </Link>
          </div>
        </div>
      </div>
    );
  }

  renderLocateITMStore() {
    const { stores, storesByLocation, actions } = this.props;

    const handleStoreSearch = (lat, long) => {
      actions.loadStoresByLocation(lat, long);
    };

    const handleStoreChange = (storeId) => {
      const updatedUserDetails = {
        Store: {
          NodeID: Number(storeId),
        },
      };
      actions.updateUserDetails(updatedUserDetails, 'store');
    };

    return (
      <LocateStore
        title="Your local ITM"
        storeDetails={storesByLocation.length > 0 ? storesByLocation : stores}
        HandleStoreSearch={handleStoreSearch}
        handleStoreChange={handleStoreChange}
      />
    );
  }

  renderLocalITM() {
    const { stores } = this.props;

    if (stores.length === 0) {
      return <Loader />;
    }

    return <LocalITM title="Your local ITM" stores={stores} />;
  }

  renderLBPDetails() {
    const { profile, actions, lbpLicenceClasses } = this.props;

    const LBPnumber = profile?.LBP?.LBPNumber || '';
    const LicenceExpiry = profile?.LBP?.LicenceExpiryDate || '';
    const selectedLicense = profile?.LBP?.LicenceClass || '';
    const licenceList = lbpLicenceClasses.map((item) => ({
      name: item.Title,
      value: item.NodeID,
    }));

    const handleLbpDetailsChange = (lbpDetails) => {
      const formFieldsValues = {
        LBP: { ...lbpDetails },
      };
      actions.updateUserDetails(formFieldsValues, 'account details');
    };

    return (
      <LBPDetails
        LBPnumber={LBPnumber}
        LicenceExpiry={formatDateForDatePicker(LicenceExpiry)}
        handleLbpDetailsChange={handleLbpDetailsChange}
        lbpLicenceClasses={licenceList}
        selectedLicense={selectedLicense}
        minDate={formatDateForDatePicker()}
      />
    );
  }

  renderPriorityCardSettings() {
    const { profile } = this.props;
    const priorityCardNumber = profile?.PriorityCardNumber;

    if (priorityCardNumber > 0) {
      return (
        <PriorityCardSettings
          title="Priority Card Settings"
          description="Please enter your priority card number"
          PriorityCardNumber={priorityCardNumber}
        />
      );
    } else {
      return this.renderPriorityCardLink();
    }
  }

  renderTradeClubSettings() {
    const { profile } = this.props;
    const tradeClubNumber = profile?.TradeClub?.MemberNo;

    return (
      <TradeClubSettings
        title="Trade club Settings"
        description="Edit your trade Club details below:"
        tradeClubNumber={tradeClubNumber}
      />
    );
  }

  renderPriorityCardLink() {
    const {actions} = this.props;

    const handlePriorityCardLink = (cardNumber) => {
      let updatedUserDetails = {
        PriorityCardNumber: cardNumber,
      };
  
      const priorityCardRegex = /^[9]\d{7}$/;
  
      if (
        cardNumber &&
        cardNumber != null &&
        cardNumber.match(priorityCardRegex)
      ) {
        actions.updateUserDetails(updatedUserDetails, 'account details');
      } else {
        ToastNotification(
          'error',
          'Invalid Card Number',
          'Check the number is entered correctly or contact the ITM support office',
          1500,
        );
      }
    }; 

    return (
      <PriorityCardLink
        title="Priority Card Linking"
        description="Please enter your priority card number"
        handlePriorityCardLink={handlePriorityCardLink}
      />
    );
  }

  renderTradeClubLink() {
    const { actions } = this.props;

    const handleTradeClubLink = (cardNumber) => {
      const updatedUserDetails = {
        TradeClub: {
          MemberNo: cardNumber,
        },
      };
  
      const clubCardRegex = /^[A-Z]{3}[0-9]{7}$/;
  
      if (cardNumber && cardNumber != null && cardNumber.match(clubCardRegex)) {
        actions.updateUserDetails(updatedUserDetails, 'account details');
      } else {
        ToastNotification(
          'error',
          'Invalid Account Number',
          'Check the number is entered correctly or contact the ITM support office',
          1500,
        );
      }
    };

    return (
      <TradeClubLink
        title="Trade club Linking"
        description="Please enter your Trade Club number"
        handleTradeClubLink={handleTradeClubLink}
      />
    );
  }

  renderAccountDetails() {
    const { profile, actions, customerTypes } = this.props;

    const customerType = profile?.UserSettings?.CustomerType;
    const firstName = profile?.Personal?.FirstName;
    const LastName = profile?.Personal?.LastName;
    const email = profile?.Personal?.Email;

    const handleAccountDetails = (userData) => {
      actions.updateUserDetails(userData, 'account details');
    };

    return (
      <>
        {firstName && LastName && email && customerTypes && (
          <AccountDetails
            firstName={firstName}
            lastName={LastName}
            email={email}
            customerTypes={customerTypes}
            customerType={customerType}
            handleAccountDetails={handleAccountDetails}
          />
        )}
      </>
    );
  }

  render() {
    const { pageType } = this.props;

    return (
      <>
        {
          /* initial page of Account */
          pageType === 'Account' && this.renderAccount()
        }
        {
          /* Account Details */
          pageType === 'Account Details' && this.renderAccountDetails()
        }
        {
          /* locate ITM Store Locate */
          pageType === 'Your local ITM' && this.renderLocateITMStore()
        }
        {
          /* local ITM */
          pageType === 'Local ITM' && this.renderLocalITM()
        }
        {
          /* LBP Details*/
          pageType === 'LBP Details' && this.renderLBPDetails()
        }
        {
          /* Prioritycard Settings */
          pageType === 'Priority Card Settings' &&
            this.renderPriorityCardSettings()
        }
        {
          /* Link priority card */
          pageType === 'Priority Card Link' && this.renderPriorityCardLink()
        }
        {
          /* Trade Club Settings */
          pageType === 'Trade club settings' && this.renderTradeClubSettings()
        }
        {
          /* Trade Club linking */
          pageType === 'Trade club link' && this.renderTradeClubLink()
        }
        <StickyNavigation activeTabName="account-profile" />
      </>
    );
  }
}

AccountsContainer.propTypes = {
  profile: PropTypes.object,
  loading: PropTypes.bool,
  storesByLocation: PropTypes.array,
  stores: PropTypes.array,
  customerTypes: PropTypes.array,
  lbpLicenceClasses: PropTypes.array,
  actions: PropTypes.object,
  pageType: PropTypes.string,
};

AccountsContainer.defaultProps = {
  loading: true,
  profile: {},
  stores: [],
  storesByLocation: [],
  lbpLicenceClasses: [],
  customerTypes: [],
};

const mapStateToProps = (state) => {
  const {
    account: { loading, profile, lbpLicenceClasses, customerTypes },
    stores: { storesByLocation, stores },
  } = state;

  return {
    loading,
    profile,
    stores,
    storesByLocation,
    customerTypes,
    lbpLicenceClasses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsContainer);
